module.exports = [{
      plugin: require('/opt/build/repo/packages/notifications/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser'),
      options: {"plugins":[],"theme":{"typography":{"useNextVariants":true,"fontFamily":"Roboto,-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""}}},
    },{
      plugin: require('/opt/build/repo/packages/notifications/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Roboto:300,400"]}},
    },{
      plugin: require('/opt/build/repo/packages/notifications/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://notifications.bigland.co"},
    }]
